import { useState } from 'react';

import 'swiper/css';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';

import { BannersProps } from 'contexts/hooks/useConfiguracoes';
import {
    Autoplay,
    Navigation,
} from 'swiper/modules';

type BannersCarouselProps = {
    urlsBanners: BannersProps[]
}

export default function BannersCarousel({ urlsBanners }: BannersCarouselProps) {
    const [controlledSwiper, setControlledSwiper] = useState(null);

    const renderProperties = urlsBanners?.map((item, index) => (
        <SwiperSlide key={index}>
            <a href={item.linkBanner} target='_blank'>
                <img width="100%" src={item.urlBanner} />
            </a>
        </SwiperSlide>
    ));

    return (
        <section>
            <div className="container">
                <div className="items">
                    <Swiper
                        loop={true}
                        navigation
                        slidesPerView={1}
                        spaceBetween={30}
                        modules={[Autoplay, Navigation]}
                        controller={{
                            control: controlledSwiper,
                        }}
                        autoplay={{
                            delay: 3000,
                        }}
                        style={{
                            paddingBlock: 30,
                        }}>
                        {renderProperties}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}