import React, {useState} from 'react';

import 'swiper/css';
import 'swiper/css/pagination';

import {Swiper, SwiperSlide} from 'swiper/react';

import {
  Autoplay,
  Navigation,
} from 'swiper/modules';
import CardImmobile from '@components/CardImmobile';
import {useMediaQuery} from 'usehooks-ts';

export default function FeaturedProperties({data, title}: any) {
  const match = useMediaQuery('(max-width: 488px)');
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const renderProperties = data?.map((item, index) => (
    <SwiperSlide key={index}>
      <CardImmobile immobile={{...item}} />
    </SwiperSlide>
  ));

  if (!data || data.length === 0) return null;

  return (
    <section>
      <div className="container">
        <header className="section-header text-center mb-4 pb-3">
          <p>{title}</p>
        </header>

        <div
          className="items"
          style={{
            height: 'auto',
          }}>
          <Swiper
            loop={true}
            navigation
            slidesPerView={match ? 1 : 3}
            spaceBetween={30}
            modules={[Autoplay, Navigation]}
            controller={{
              control: controlledSwiper,
            }}
            autoplay={{
              delay: 3000,
            }}
            style={{
              paddingBlock: 30,
            }}>
            {renderProperties}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
