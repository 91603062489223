import styled, { css, keyframes } from 'styled-components';

import { Button, Select, Tag, TreeSelect } from 'antd';
import { Col } from 'reactstrap';

export const Container = styled(Col)`
@media (max-width: 988px) {
    width: auto;
    padding-inline: 15px;
  }
`;
export const TabContainer = styled.div`
`;

const slideInLeft = keyframes`
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
`;

export const Tabs = styled.ul`
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  width: 200px;
  background-color: #ffff;
  border-radius: 8px 8px 0px 0px;
  padding: 10px;
  gap: 10px;

  @media (max-width: 988px) {
    width: 100%;
  }
`;
export const Tab = styled.li`
  cursor: pointer;
  width: 100px;
  margin: 0px;

  @media (max-width: 988px) {
    width: 100%;
  }
`;
export const SearchInputContainer = styled.div`
  width: 100%;
  height: 60px;

  background-color: #ffff;
  padding: 12px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  gap: 10px;

  -webkit-border-radius: 8px;
  -webkit-border-top-left-radius: 0;
  -moz-border-radius: 8px;
  -moz-border-radius-top-left: 0;
  border-radius: 8px;
  border-top-left-radius: 0;

  @media (max-width: 988px) {
    flex-direction: column;
    height: auto;
    border-top-right-radius: 0px;

    > button {
      width: 100%;
    }
  }
`;
export const SearchInput = styled.input``;
export const TabButton = styled.button<{ active: boolean }>`
  border: none;
  border-radius: 8px;
  background-color: transparent;
  padding: 6px;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  > span {
    font-size: 16px;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: aliceblue;
      animation-name: ${slideInLeft};
      animation-duration: 0.5s;

      > span {
        font-weight: bold;
      }

      .line {
        width: 100%;
        height: 2px;
        background-color: #000000;
        z-index: 3;
      }
    `}

  @media (max-width: 988px) {
    width: 100%;
    align-items: center;
  }
`;

export const CategorySelect = styled(TreeSelect)`
  width: 25%;
  @media (max-width: 988px) {
    width: 100%;
  }
`;

export const SearchButton = styled(Button)`
  width: 15%;
  height: 100%;
  background-color: var(--tertiary_color);
`;
export const TagSelect = styled(Select)`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 60%;
  height: 100%;

  @media (max-width: 500px) {
    width: 100%;
  }

  .ant-select-selection-item {
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  .ant-select-selector {
    width: 100%;
  }

  .ant-select-selection-overflow {
    overflow: hidden;
  }

  .anticon-close {
    color: #fff;
    background-color: rgb(0, 43, 82);
    padding: 2px;
    display: flex;
    width: fit-content;
    border-radius: 999px;
  }
`;
export const TagSelectItem = styled(Tag)`
  color: #000;
  background-color: aliceblue;
  display: flex;
  align-items: center;
  gap: 5px;

  .anticon,
  anticon-close,
  ant-tag-close-icon {
    color: #fff;
    background-color: rgb(0, 43, 82);
    padding: 2px;
    display: flex;
    width: fit-content;
    border-radius: 999px;
  }
`;
