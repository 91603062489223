/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

import api from '@services/api';

async function fetchConfiguracoes(): Promise<any> {
    const empresaId = process.env.NEXT_PUBLIC_EMPRESA_ID;

    const responseData = await api
        .get(`configuracoes-site/getConfiguracoes/${empresaId}`)
        .then((res: any) => {
            return res.data.data;
        });

    return responseData;
}

export default fetchConfiguracoes;
