//@ts-ignore
import React, {useState, useEffect} from 'react';
import {Row, Col, Container} from 'reactstrap';
import {Carousel} from 'antd';
import {isDesktop} from 'react-device-detect';
import {useRouter} from 'next/router';

import SkeletonAsync from '@components/Skeleton';
import CardImmobile from '@components/CardImmobile';
import Button from '@components/ButtonDynamic';
import {ImmobileProps} from 'types/immobileProps';

const LoadStructure = () => {
  return (
    <div className="d-flex flex-wrap justify-content-between pt-50">
      <Container>
        <Row>
          <Col lg="4">
            <SkeletonAsync />
          </Col>
          <Col lg="4">
            <SkeletonAsync />
          </Col>
          <Col lg="4">
            <SkeletonAsync />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const RenderCards = (imovel: {
  codigoExterno: any;
  imagens?: any;
  data?: any;
  operacaoImobiliaria?: number;
  tipoImovel?: any;
  caracteristica?: any;
  tituloAmigavel?: string;
  logradouro?: any;
  valorAluguel?: number;
  valorVenda?: number;
  valorCondominio?: number;
  codImovel?: number;
  endereco?: {condominio: any};
  length?: number;
}) => {
  return (
    <Col xs="12" md="4" key={imovel?.codImovel}>
      <div className={isDesktop ? 'm-2' : 'm-0'}>
        <CardImmobile immobile={imovel} />
      </div>
    </Col>
  );
};

type SpotLightProps = {
  dataImmobile: ImmobileProps;
  title: string;
  subTitle?: string;
  textButton?: string;
  hasButton?: boolean;
  hasButtonLink?: string;
};

const SpotLight = ({
  dataImmobile,
  title,
  textButton = 'Ver todos os imóveis',
  hasButton = false,
  hasButtonLink,
}: SpotLightProps): JSX.Element => {
  const [data, setData] = useState<any>([]);
  const [loadData, setLoadData] = useState<boolean>(true);
  const [grupoDestaque, setGrupoDestaque] = useState<any[]>([]);
  const router = useRouter();

  useEffect(() => {
    const hasDestaque = [];
    if (dataImmobile) {
      dataImmobile.data.map((item: {divulgavel: any}) => {
        if (item.divulgavel) {
          hasDestaque.push(item);
        }
      });
    }

    if (hasDestaque.length >= 1) {
      const gpDestaque = [];
      for (let i = 0; i < hasDestaque.length; i = i + 3) {
        gpDestaque.push(hasDestaque.slice(i, i + 3));
      }
      setGrupoDestaque(gpDestaque);
      setData(hasDestaque);
    }

    setTimeout(() => {
      setLoadData(false);
    }, 1100);
  }, [dataImmobile]);

  const hasImoveis = data.length > 0;

  return (
    <>
      <Container>
        <Row className="justify-content-center no-gutters pb-4">
          {loadData ? (
            <div className="my-4">
              {/* <Carousel autoplay> */}
              <LoadStructure />
              {/* <LoadStructure /> */}
              {/* </Carousel> */}
            </div>
          ) : null}
          {hasImoveis ? (
            <div className="mt-4">
              <header className="section-header text-center mb-4 pb-3">
                <p>{title}</p>
              </header>
              <Carousel>
                {grupoDestaque[0] ? (
                  <div className="d-flex flex-wrap justify-content-center align-items-center pb-50">
                    <Container>
                      <Row className="justify-content-center ">
                        {grupoDestaque[0].map((imob: any) => {
                          return RenderCards(imob);
                        })}
                      </Row>
                    </Container>
                  </div>
                ) : null}

                {grupoDestaque[1] ? (
                  <div className="d-flex flex-wrap justify-content-center align-items-center pb-50">
                    <Container>
                      <Row className="justify-content-center ">
                        {grupoDestaque[1].map((imob: any) => {
                          return RenderCards(imob);
                        })}
                      </Row>
                    </Container>
                  </div>
                ) : null}
              </Carousel>
              {hasButton ? (
                <Row className="mt-4 justify-content-md-center no-gutters">
                  <Col xs="12" md="3">
                    <Button
                      basic
                      size="big"
                      fluid
                      color="orange"
                      onClick={() => router.replace(hasButtonLink)}>
                      {textButton}
                    </Button>
                  </Col>
                </Row>
              ) : null}
            </div>
          ) : null}
        </Row>
      </Container>
    </>
  );
};

export default SpotLight;
