import { useAppContexto } from 'contexts/AppContext';

export const SpotContent = ({ icon, title, text, subText, url }): JSX.Element => {
  return (
    <a href={url} role="button" target="_blank">
      <div className="spot-title">
        <img src={icon} width="120px" height="120px" />
        <h2 className="title-service">{title}</h2>
        <span className="content-service">{text}</span>
        <span className="content-service">{subText}</span>
      </div>
    </a>
  );
};

const Service = (): JSX.Element => {
  const { configuracoes, setConfiguracoes } = useAppContexto();
  return (
    <>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <header className="section-header text-center mb-4 pb-3">
            <p>Confira nossos Serviços</p>
          </header>

          <div className="row gy-4">
            <div
              className="col-md-2"
              data-aos="fade-up"
              data-aos-delay="300"></div>
            {(configuracoes?.tipoOperacao === 1 || configuracoes?.tipoOperacao === 3 || configuracoes?.tipoOperacao === 0 || configuracoes?.tipoOperacao === undefined) && (<div className="col-md-4" data-aos="fade-up" data-aos-delay="200">
              <a href="/busca-rapida?operacaoImobiliaria=Alugar" target="_blank">
                <div className="service-box blue">
                  <i className="ri-home-3-line icon"></i>
                  <h3>Aluguel </h3>
                </div>
              </a>
            </div>)}
            {/* <div
              className="col-lg-4 col-md-6"
              data-aos="fade-up"
              data-aos-delay="300">
              <div className="service-box blue">
                <img
                  src="/img/icone-juridico.png"
                  width="100px"
                  height="100px"
                />
                <h3>Acessoria Jurídica</h3>
              </div>
            </div> */}

            {(configuracoes?.tipoOperacao === 2 || configuracoes?.tipoOperacao === 3 || configuracoes?.tipoOperacao === 0 || configuracoes?.tipoOperacao === undefined) && (<div className="col-md-4" data-aos="fade-up" data-aos-delay="400">
              <a href="/busca-rapida?operacaoImobiliaria=Comprar" target="_blank">
                <div className="service-box blue">
                  <i className="ri-refund-2-line icon"></i>
                  <h3>Venda</h3>
                </div>
              </a>
            </div>)}
            <div
              className="col-md-2"
              data-aos="fade-up"
              data-aos-delay="300"></div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Service;
