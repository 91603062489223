import { Form, Select } from 'antd';
import { NextSeo } from 'next-seo';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import Hero from '@components/Hero';
import Layout from '@components/Layout';
import Service from '@components/Service';
import Whatsapp from '@components/Whats';
import SpotLight from '@components/SpotLight';
import fetchCitiesGroup from '@services/fetch-cities-group';
import fetchImMobile from '@services/fetch-immobile';
import fetchImMobileGoal from '@services/fetch-immobile-goal';

import BannersCarousel from '@components/BannersCarousel';
import useContact from '@components/Contact/hooks/use-contact';
import FeaturedProperties from '@components/FeaturedProperties';
import { useAppContexto } from 'contexts/AppContext';
import useConfiguracoes from 'contexts/hooks/useConfiguracoes';

export async function getStaticProps() {
  const empreendimentos = await fetchImMobile({
    props: {
      empreendimento: true,
    },
  });
  const popularData = await fetchImMobile({
    props: {
      destaque: true,
    },
  });

  const newerData = await fetchImMobile({
    props: {
      novidade: true,
    },
  });

  const cityGroup = await fetchCitiesGroup();
  const imMobileGoalData = await fetchImMobileGoal();

  let props = {};

  if (popularData) {
    props = {
      ...props,
      popular: popularData,
    };
  }

  if (newerData) {
    props = {
      ...props,
      novidade: newerData,
    };
  }

  if (cityGroup) {
    props = {
      ...props,
      cidadesPorGrupo: cityGroup,
    };
  }
  if (empreendimentos) {
    props = {
      ...props,
      empreendimentos: empreendimentos,
    };
  }
  if (imMobileGoalData) {
    props = {
      ...props,
      imMobileGoal: imMobileGoalData,
    };
  }

  return {
    props,
  };
}

type HomePageProps = {
  popular?: any;
  cidadesPorGrupo: any;
  empreendimentos: any;
  imMobileGoal: any;
  novidade?: any;
};

const Home = ({
  cidadesPorGrupo,
  popular,
  novidade,
  empreendimentos,
  imMobileGoal,
}: HomePageProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('Buscando imóveis...');
  const [showDrawer, setShowDrawer] = useState(false);
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const [cityCookies, setCityCookies] = useState(null);
  const { configuracoes, setConfiguracoes } = useAppContexto();
  const { configuracoes: configuracoesData } = useConfiguracoes()

  const [form] = Form.useForm();
  const { Option } = Select;

  function setPageCookie(cookie: any): void {
    setAcceptedCookies(true);
    const { name, value, exdays } = cookie;
    const expiration: Date = new Date('Thu, 01 Jan 1970 00:00:01 GMT');

    if (exdays > 0)
      expiration.setMilliseconds(expiration.getMilliseconds() + exdays * 864e5);
    setCookie(null, name, value, {
      maxAge: 30 * 30,
      path: '/',
      expires: expiration,
    });
    setShowDrawer(false);
  }

  const acceptingCookie = (value) => {
    setShowDrawer(value);
    window.scrollTo(0, 0);
  };

  const hasOnChange = (value) => {
    setCityCookies(value);
  };

  const {companyList} = useContact();
  const phoneNumber = `55${String(configuracoes?.telefoneWhatsapp || configuracoes?.telefoneRodape || companyList[0]?.phone).replace(/\D/g, '')}` || null;
  const COOKIE_NAME = 'mb_state';

  useEffect(() => {
    setConfiguracoes(configuracoesData)
  }, [configuracoesData])

  useEffect(() => {
    window.scrollTo(0, 0);
    const cookies = parseCookies();
    if (!cookies[COOKIE_NAME]) {
      setAcceptedCookies(false);
      setShowDrawer(true);
    }
  }, []);

  const hasSpotLight = popular?.data?.length > 0 || empreendimentos?.data?.length > 0;
  const companyLogo = process.env.NEXT_PUBLIC_URL_LOGO ?? configuracoes?.urlLogoMarca;

  return (
    <>
      <NextSeo
        title="Soluções Imobiliárias"
        description="Imovéis para venda e locação"
        canonical="https://redelatitude.com.br"
        openGraph={{
          url: 'https://redelatitude.com.br',
          type: 'website',
          title: 'Imobiliárias',
          description: 'Imovéis para venda e locação',
          images: [
            {
              url: companyLogo,
              width: 800,
              height: 600,
              alt: 'Soluções Imobiliárias',
              type: 'image/png',
            },
          ],
          site_name: 'Soluções Imobiliárias',
        }}
      />
      <Hero
        finalidades={imMobileGoal}
        cityGroup={cidadesPorGrupo}
        cidadeId={Number(cityCookies)}
        setIsLoading={(v) => setIsLoading(v)}
        setLoadingText={(v) => setLoadingText(v)}
      />
      {hasSpotLight ? (
        <section className="spotlight">
          <FeaturedProperties 
            title="Imóveis em destaque"
            data={popular?.data || []}
          />
          <SpotLight
            dataImmobile={empreendimentos}
            title="Imóveis lançamentos"
            hasButton
            hasButtonLink="/busca-rapida"
          />
        </section>
      ) : null}
      {configuracoes && (<BannersCarousel urlsBanners={configuracoes?.banners} />)}
      {/* <CallToAction
        title="Proprietário"
        subTitle="Quer anunciar seu imóvel ?"
        hasButton
        textButton="Converse com um dos nossos especialistas"
      /> */}

      {novidade?.data && novidade?.data.length > 0 && (
        <FeaturedProperties title="Novidades" data={novidade?.data} />
      )}

      {/* <CalculatorBanner
        title="Calculadora e simulador de financiamento imobiliário"
        subTitle="Use nossa calculadora para descobrir seu potencial de compra e escolha 
        como usá-lo da forma mais inteligente possível."
        hasButton
        textButton="Simule aqui o seu financiamento"
      /> */}
      <section className="spotlight">
        <Service />
        {/* <PersonalBanner
          title="Venha fazer parte da imobiliária que mais cresce no Brasil!"
          hasButton
          textButton="Seja um Personal Broker"
        /> */}
      </section>
      {phoneNumber && <Whatsapp phoneNumber={phoneNumber} />}
      {isLoading ? (
        <Dimmer active>
          <Loader size="massive">{loadingText}</Loader>
        </Dimmer>
      ) : null}
      {/* {!acceptedCookies ? (
        <Modal
          basic
          onClose={(v) => acceptingCookie(v)}
          onOpen={() => setShowDrawer(true)}
          open={showDrawer}
          size="small">
          <Modal.Content>
            <Form form={form} scrollToFirstError>
              <Row className="justify-content-md-center no-gutters">
                <Col xs="12">
                  <div className="text-center">
                    <h1 className="text-white my-4">Escolha sua Cidade</h1>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center no-gutters">
                <Col xs="12" md="10">
                  <Form.Item name="cidadeId">
                    <Select
                      size="large"
                      className="place-form"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option.children
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f]/g, '')
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder=" Cidade"
                      onChange={hasOnChange}
                      allowClear>
                      {cidadesPorGrupo.map((item) => (
                        <Option key={item.id} value={item.id}>
                          {item.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row className="justify-content-md-center no-gutters">
              <Col xs="12" md="5">
                <Button
                  icon
                  labelPosition="left"
                  fluid
                  color="blue"
                  onClick={() =>
                    setPageCookie({name: COOKIE_NAME, value: cityCookies})
                  }>
                  <Icon name="send" />
                  Selecionar
                </Button>
              </Col>
            </Row>
          </Modal.Content>
        </Modal>
      ) : null} */}
    </>
  );
};

export default Home;

Home.Layout = Layout;