import React from 'react';
import useContact from './hooks/use-contact';
import useConfiguracoes from 'contexts/hooks/useConfiguracoes';

const Contact = () => {
  const {configuracoes} = useConfiguracoes();
  const {companyList} = useContact();

  const isMatriz = process.env.NEXT_PUBLIC_IS_MATRIZ === 'true' || false;

  const renderCompanyList = companyList?.map((item, index) => (
    <div
      key={index}
      className="col-md-3"
      >
      <div className="feature-box d-flex align-items-center">
        <i className="ri-map-pin-line"></i>
        <p>
          <span>{item.name}</span>
          <br />
          <span> {item.address}</span>
          <br />
          <span>{item.phone}</span>
        </p>
      </div>
    </div>
  ));

  return (
    <section id="contact" className="features my-4">
      <div className="container">
        <div className="row feature-icons">
          <h3>Contatos</h3>
        </div>

        <div className="row  gy-4" style={{justifyContent: 'center'}}>
          {!isMatriz ? (
            renderCompanyList
          ) : (
            <div className="col-md-3" data-aos="zoom-out" data-aos-delay="200">
              <div className="feature-box d-flex align-items-center">
                <i className="ri-map-pin-line"></i>
                <p>
                  <span>{companyList[0]?.name}</span>
                  <br />
                  <span> {companyList[0]?.address}</span>
                  <br />
                  <span>{configuracoes?.telefoneRodape}</span>
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Contact;
